import React, { Fragment } from "react"
import { Helmet } from "react-helmet"
import logovideo from "../assets/pivvot_logo_animation.mp4"

export default () => (
  <Fragment>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Pivvot</title>
      <link
        href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700&display=swap"
        rel="stylesheet"
      ></link>
    </Helmet>
    <div className="container mx-auto p-4 max-w-3xl lg:max-w-4xl font-sans flex flex-col min-h-screen">
      <div className="flex-1">
        <div className="flex flex-col justify-center text-left">
          <video
            autoPlay
            loop
            muted
            playsInline
            className="w-32 h-32 md:w-64 md:h-64 md:mt-20 mx-0"
          >
            <source src={logovideo} type="video/mp4" />
          </video>
          <div className="mt-10 mb-10 max-w-2xl">
            <h1
              className="text-indigo-700 text-3xl 
            md:text-5xl font-bold leading-tight"
            >
              Pivvot is the creative studio of{" "}
              <a
                href="https://twitter.com/itstheonlychris"
                className="bg-gradient-to-r text-transparent bg-clip-text from-purple-600  to-indigo-600 hover:from-blue-600 hover:to-purple-600 transform hover:translate-y-4 transition duration-300 ease-in-out
                "
              >
                Chris Zachary
              </a>
            </h1>
          </div>
        </div>

        <p className="pb-6 text-xl">
          Here's some of the things I'm working on...
        </p>
        <ul className="list-disc ml-10 py-5">
          <li className="py-2">
            <a
              href="https://itstheonlychris.com"
              className="underline text-indigo-700 hover:text-indigo-500 "
            >
              AE Reference
            </a>{" "}
            - After Effects Tips, Resources, Expression Library & More
          </li>
          <li className="py-2">
            <a
              href="https://itstheonlychris.com"
              className="underline text-indigo-700 hover:text-indigo-500 "
            >
              AE Pro Tips Newsletter
            </a>{" "}
            - a weekly After Effects email for motion designers
          </li>

          <li className="py-2">
            <a
              href="https://twitter.com/itstheonlychris"
              className="underline text-indigo-700 hover:text-indigo-500 "
            >
              Twitter
            </a>{" "}
            - where I share what I'm working on (and After Effects stuff)
          </li>
          <li className="py-2">
            <a
              href="https://youtube.com/chriszachary0"
              className="underline text-indigo-700 hover:text-indigo-500 "
            >
              Youtube
            </a>{" "}
            - Motion Design tutorials
          </li>
          <li className="py-2">
            <a
              href="https://authors.company"
              className="underline text-indigo-700 hover:text-indigo-500 "
            >
              Authors
            </a>{" "}
            - My day job as an Animation Director
          </li>
          <li className="py-2">
            <a
              href="https://itstheonlychris.com"
              className="underline text-indigo-700 hover:text-indigo-500 "
            >
              Personal Website
            </a>{" "}
            - I share things here. Needs a refresh
          </li>
        </ul>
      </div>

      <footer className="w-full border-t border-grey p-4 pin-b">
        &copy; 2021 Chris Zachary
      </footer>
    </div>
  </Fragment>
)
